import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Post } from '@talentinc/gatsby-theme-ecom/types/post'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import BasePostTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/Post'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

const PostTemplate: React.FC<
  PageProps<{ post: Post } & PageDataContextType>
> = ({ data: { post, ...pageData } }) => {
  return (
    <Layout pageTitle={post.title} pageData={pageData}>
      <BasePostTemplate post={post} />
    </Layout>
  )
}

export const query = graphql`
  query PostsBySlugAndBrand($slug: String!, $brandName: String!) {
    post: contentfulPost(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...Post
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PostTemplate
