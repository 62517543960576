import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import SocialButton from './SocialButton'

interface Props {
  postTitle: string
}

const PostSocial: React.FC<Props> = ({ postTitle }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.box}>
      <SocialButton postTitle={postTitle} variant="copy" />
      <SocialButton postTitle={postTitle} variant="twitter" />
      <SocialButton postTitle={postTitle} variant="facebook" />
      <SocialButton postTitle={postTitle} variant="linkedin" />
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
}))

export default PostSocial
