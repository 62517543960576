import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import formatDate from '@talentinc/gatsby-theme-ecom/utils/date'
import { AssetFluid } from '../../types/asset'
import { Category } from '../../types/category'
import { Author } from '../../types/post'
import Image from '../ContentfulEntries/Image'
import Breadcrumbs from './Breadcrumbs'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'
import Link from '../Link'

interface Props {
  authors: Author[] | null
  categories: Category[] | null
  featuredImage: AssetFluid | null
  postDate: string
  publishedAt: string
  readingTime: number
  title: string
}

const PostHeader: React.FC<Props> = ({
  authors,
  categories,
  featuredImage,
  postDate,
  publishedAt,
  readingTime,
  title,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const date = publishedAt ? publishedAt : postDate
  const { blogPrefix, urlPathBuilder } = useUrlPathBuilder()

  return (
    <Box className={classes.header} component="header">
      <Box className={cx(classes.column, classes.text)}>
        {categories?.length && (
          <Breadcrumbs
            classes={{
              root: cx(classes.flexContainer, classes.breadcrumbs),
            }}
            links={[
              {
                text: 'Blog',
                to: urlPathBuilder([blogPrefix]),
              },
              {
                text: categories[0].name,
                to: urlPathBuilder([
                  blogPrefix,
                  'category',
                  categories[0].slug,
                ]),
              },
            ]}
          />
        )}
        <Typography className={classes.h1} variant="h1" data-qa="post-title">
          {title}
        </Typography>
        {authors?.length && (
          <Container className={classes.flexContainer} disableGutters>
            {authors[0].picture && (
              <Image
                asset={authors[0].picture}
                className={classes.authorImage}
                imgClassName={classes.authorImageTag}
                GatsbyImageProps={{
                  loading: 'eager',
                }}
              />
            )}
            <Container className={classes.authorDate} disableGutters>
              {authors[0].authorName ? (
                <Link
                  className={classes.caption}
                  to={urlPathBuilder([authors[0].authorName.url])}
                  variant="caption"
                >
                  {authors[0].authorName.text}
                </Link>
              ) : (
                <Typography
                  className={classes.caption}
                  component="p"
                  variant="caption"
                >
                  {authors[0].name}
                </Typography>
              )}
              <Typography
                component="time"
                variant="caption"
                dateTime={date}
                className={classes.publishedAt}
              >
                {formatDate(date, t('postHeader.dateFormat'))}
              </Typography>
              <Typography
                className={cx(classes.caption, classes.readTime)}
                component="p"
                variant="caption"
              >
                {t('postHeader.readingTime', {
                  minutes: Math.ceil(readingTime),
                })}
              </Typography>
            </Container>
          </Container>
        )}
      </Box>
      {featuredImage && (
        <Image
          asset={featuredImage}
          className={cx(classes.column, classes.img)}
          GatsbyImageProps={{
            loading: 'eager',
          }}
        />
      )}
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  authorDate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  authorImage: {
    marginRight: '.75em',
    width: '3.5em',
    borderRadius: '100%',
  },
  authorImageTag: {
    '&&': {
      borderRadius: '100%',
    },
  },
  caption: {
    fontWeight: 700,
  },
  category: {
    color: theme.palette.background.default,
  },
  column: {
    height: '100%',
    width: '100%',
  },
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  h1: {
    margin: '.43em 0 .74em',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.81em',
    },
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    height: '26.5em',
    justifyContent: 'space-between',
    maxWidth: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
  },
  img: {
    maxHeight: 'initial !important',
    maxWidth: 'initial !important',
    [theme.breakpoints.down('md')]: {
      '&&': {
        display: 'none',
      },
    },
  },
  readTime: {
    fontWeight: 400,
  },
  publishedAt: {
    fontStyle: 'italic',
  },
  breadcrumbs: {
    marginBottom: '0.75em',
  },
  text: {
    color: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1em 2.03em 1em 6%',

    [theme.breakpoints.down('md')]: {
      padding: '2.5em 6% 2.5em 6%',
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      padding: '1em 2.03em 1em calc((100% - 91rem)/2 + 5.5rem)',
      width: '100%',
    },
  },
}))

export default PostHeader
