import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Trans } from 'gatsby-plugin-react-i18next'
import useTrustPilot from '../../hooks/useTrustPilot'
import SvgTrustPilotLogo from '../../images/trust-pilot-logo.inline.svg'

const FiveStarReviews: React.FC = () => {
  const { classes } = useStyles()
  const trustPilot = useTrustPilot()
  const fiveStars = trustPilot?.businessUnit?.numberOfReviews?.fiveStars
    ? trustPilot.businessUnit.numberOfReviews.fiveStars
    : 800
  const roundedFiveStars = Math.floor(fiveStars / 50) * 50

  if (!trustPilot) {
    return null
  }

  return (
    <Box
      className={classes.box}
      component="a"
      // @ts-ignore
      href={trustPilot.links.profileUrl}
    >
      <Typography variant="caption">
        <Trans
          i18nKey="trustpilot.fiveStars"
          values={{
            reviews: roundedFiveStars,
          }}
          components={{
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
            strong: <strong />,
          }}
        />
      </Typography>
      <SvgTrustPilotLogo className={classes.logo} />
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  logo: {
    height: '1.13em',
    marginLeft: '.5em',
    width: '4.58em',
  },
}))

export default FiveStarReviews
