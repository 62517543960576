import React from 'react'
import { Container, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { KeyboardArrowRight } from '@mui/icons-material'
import Link from '@talentinc/gatsby-theme-ecom/components/Link'

interface Props {
  links: Array<{ text: string } & ({ href: string } | { to: string })>
  classes?: Partial<{
    root: string
    link: string
    seperator: string
  }>
}

const Breadcrumbs: React.FC<Props> = ({ links, classes: propClasses = {} }) => {
  const { classes, cx } = useStyles()

  return (
    <Container
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      component="ul"
      className={cx(classes.root, propClasses.root)}
    >
      {links.map(({ text, ...linkProps }, i) => (
        <React.Fragment key={'to' in linkProps ? linkProps.to : linkProps.href}>
          <Typography
            itemScope
            itemProp="itemListElement"
            itemType="https://schema.org/ListItem"
            component="li"
            className={classes.crumb}
            variant="overline"
          >
            <Link
              itemProp="item"
              className={cx(classes.link, propClasses.link)}
              {...linkProps}
            >
              <span itemProp="name">{text}</span>
              <meta itemProp="position" content={(i + 1).toString()} />
            </Link>
          </Typography>
          {i + 1 < links.length && (
            <KeyboardArrowRight
              className={cx(classes.seperator, propClasses.seperator)}
              fontSize="inherit"
            />
          )}
        </React.Fragment>
      ))}
    </Container>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    listStyle: 'none',
    padding: 0,
    margin: 0,

    '& > li': {
      display: 'inline',
      padding: 0,
      verticalAlign: 'middle',
    },
  },
  crumb: {
    letterSpacing: '1px',
    color: theme.palette.background.default,
    lineHeight: 1,
  },
  link: {
    color: theme.palette.background.default,

    '&:hover, &:focus': {
      color: theme.palette.background.default,
      textDecoration: 'underline',
    },
  },
  seperator: {
    fontSize: '1.3em',
  },
}))

export default Breadcrumbs
