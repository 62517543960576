import React, { useEffect, useRef, useState } from 'react'
import { Container, Divider, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Post, PostResumeSample } from '../../types/post'
import RichText, { RichTextOptions } from '../RichText'
import AuthorProfile from '../Post/AuthorProfile'
import CallToAction from '../CallToAction'
import PostHeader from '../Post/PostHeader'
import SamplePostHeader from '../Post/SamplePostHeader'
import PostSocial from '../Post/PostSocial'
import { useScrollProgress } from '../Post/ScrollProgress'
import XRayWrapper from '../XRay/XRayWrapper'

interface Props {
  post: Post | PostResumeSample
  richTextOptions?: RichTextOptions
}

const PostTemplate: React.FC<Props> = ({ post, richTextOptions = {} }) => {
  const { classes, cx } = useStyles()
  const { ref, scrollProgress } = useScrollProgress()
  const postEl = useRef<HTMLElement | null>(null)
  const sidebarEl = useRef<HTMLElement | null>(null)
  const [sidebarTop, setSidebarTop] = useState(true)
  const [sidebarBottom, setSidebarBottom] = useState(false)

  useEffect(() => {
    if (postEl?.current && sidebarEl?.current) {
      const postTop: number = postEl.current.getBoundingClientRect().top
      const postBottom: number = postEl.current.getBoundingClientRect().bottom
      const sidebarElHeight: number =
        sidebarEl.current.getBoundingClientRect().height
      setSidebarTop(postTop > 66)
      setSidebarBottom(postBottom - sidebarElHeight < 250)
    }
  }, [postEl, sidebarEl, scrollProgress])

  return (
    <XRayWrapper id={post.contentful_id}>
      <Box
        className={classes.article}
        component="article"
        // @ts-ignore
        ref={ref}
      >
        {post.__typename === 'ContentfulPost' && (
          <PostHeader
            authors={post.authors}
            categories={post.categories}
            featuredImage={post.featuredImage}
            postDate={post.date}
            publishedAt={post.publishedAt}
            readingTime={post.fields.readingTime.minutes}
            title={post.title}
          />
        )}
        {post.__typename === 'ContentfulPostResumeSample' && (
          <SamplePostHeader
            bannerImage={post.bannerImage}
            bannerImageMobile={post.bannerImageMobile}
            downloadFile={post.downloadFile.file.url}
            featuredImages={post.featuredImages}
            headerText={post.headerText}
            occupation={post.occupation}
          />
        )}
        <Container
          className={classes.main}
          component="main"
          disableGutters
          // @ts-ignore
          ref={postEl}
        >
          <Container className={classes.socialContainer} disableGutters>
            <PostSocial postTitle={post.title} />
          </Container>
          <Container className={classes.text} disableGutters>
            <Container
              className={cx({
                [classes.richTextContainer]: true,
                [classes.richTextContainerSample]:
                  post.__typename === 'ContentfulPostResumeSample',
              })}
              disableGutters
            >
              <RichText
                rawBody={post.body.raw}
                options={{
                  ...richTextOptions,
                  props: {
                    [BLOCKS.HEADING_2]: {
                      className: classes.postBodyH2,
                    },
                    [BLOCKS.HEADING_3]: {
                      className: classes.postBodyH3,
                    },
                    [BLOCKS.HEADING_4]: {
                      className: classes.postBodyH4,
                    },
                    [BLOCKS.HR]: {
                      className: classes.postBodyHR,
                    },
                    [BLOCKS.PARAGRAPH]: {
                      className: classes.postBodyP,
                    },
                    [BLOCKS.OL_LIST]: {
                      className: classes.postBodyOL,
                    },
                    [BLOCKS.UL_LIST]: {
                      className: classes.postBodyUL,
                    },
                    [BLOCKS.LIST_ITEM]: {
                      className: classes.postBodyLI,
                    },
                    [BLOCKS.QUOTE]: {
                      className: classes.postBodyBlockQuote,
                    },
                    [INLINES.HYPERLINK]: {
                      className: classes.postLink,
                    },
                    [INLINES.ENTRY_HYPERLINK]: {
                      className: classes.postLink,
                    },
                    [INLINES.ASSET_HYPERLINK]: {
                      className: classes.postLink,
                    },
                  },
                }}
                references={post.body.references}
              />
            </Container>
            {post.__typename === 'ContentfulPost' && (
              <>
                <Divider className={classes.divider} />
                <Container className={classes.authors} disableGutters>
                  <>
                    {post.authors?.map((author) => (
                      <AuthorProfile author={author} key={author.name} />
                    ))}
                  </>
                </Container>
              </>
            )}
          </Container>
          <Container className={classes.sidebarContainer} disableGutters>
            <Container
              className={cx({
                [classes.sidebar]: true,
                [classes.sidebarTop]: sidebarTop,
                [classes.sidebarBottom]: sidebarBottom,
                [classes.sidebarMid]: !sidebarTop && !sidebarBottom,
              })}
              component="div"
              disableGutters
              // @ts-ignore
              ref={sidebarEl}
            >
              <>{post.sidebarCTA && <CallToAction cta={post.sidebarCTA} />}</>
            </Container>
          </Container>
        </Container>
      </Box>
    </XRayWrapper>
  )
}

const useStyles = makeStyles()((theme) => ({
  article: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  authors: {
    marginBottom: '3.56em',
    maxWidth: '100%',
  },
  divider: {
    margin: '4.02em 0 3.74em',
  },
  main: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    padding: '6.03em 6% 4.02em 6%',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      padding: '3.5em 6% 3.5em 6%',
    },
  },
  postBody: {},
  postLink: {
    textDecoration: 'underline',
  },
  postBodyBlockQuote: {
    borderLeft: `.25em solid ${theme.palette.text.primary}`,
    margin: '1.13em 0',
    padding: '.75em 0 .75em 1.13em',
  },
  postBodyH2: {
    fontSize: '1.81em',
    fontWeight: 700,
    margin: '2.1em 0 -.1em',

    '&:first-child': {
      marginTop: '0',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.38em',
      margin: '2.25em 0 -.35em',
    },
  },
  postBodyH3: {
    fontSize: '1.5em',
    margin: '1.37em 0 -.1em',

    '&:first-child': {
      marginTop: '0',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.13em',
      margin: '1.37em 0 -.45em',
    },
  },
  postBodyH4: {
    margin: '1.37em 0 0',

    '&:first-child': {
      marginTop: '0',
    },
  },
  postBodyHR: {
    margin: '2em 0',
  },
  postBodyLI: {
    margin: '.61em 0',

    '&::marker': {
      color: theme.palette.text.disabled,
      fontWeight: 700,
    },
  },
  postBodyOL: {
    paddingLeft: '1em',
    listStyle: 'decimal outside',

    '& li': {
      paddingLeft: '.8em',
      '&::marker': {
        color: 'inherit',
      },

      '& p': {
        display: 'inline',
      },
    },
  },
  postBodyP: {
    marginTop: '.97em',

    '&:first-child': {
      marginTop: '0',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
      lineHeight: '1.81em',
    },
  },
  postBodyUL: {
    paddingLeft: '1.2em',
  },
  richTextContainer: {
    maxWidth: '100%',

    '& br': {
      '&:last-child': {
        display: 'none',
      },
    },
  },
  richTextContainerSample: {
    marginBottom: '3.56em',
  },
  sidebar: {
    position: 'relative',
    width: '14.38em',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sidebarBottom: {
    bottom: '0',
    position: 'absolute',
  },
  sidebarContainer: {
    margin: '0',
    position: 'relative',
    width: '14.38em',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sidebarMid: {
    position: 'fixed',
    top: '10.5em',
  },
  sidebarTop: {
    position: 'absolute',
  },
  socialContainer: {
    margin: '0',
    width: '14.38em',
    [theme.breakpoints.down('lg')]: {
      width: '2.16em',
    },
    [theme.breakpoints.down('md')]: {
      margin: 'initial',
      width: 'initial',
    },
  },
  text: {
    margin: '0 2em',
    maxWidth: 'initial',
    width: '45.25em',
    [theme.breakpoints.down('lg')]: {
      width: '61%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '1.92em 0 0',
      width: '100%',
    },
  },
}))

export default PostTemplate
