import React, { useCallback, useState } from 'react'
import { Container, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { Twitter } from '@mui/icons-material'
import SvgFacebook from '../../images/facebook.inline.svg'
import SvgLink from '../../images/link.inline.svg'
import SvgLinkedIn from '../../images/linkedin.inline.svg'
import useLocation from 'react-use/lib/useLocation'
import useForceClientSideRender from '../../hooks/useForceClientSideRender'

interface Props {
  postTitle: string
  variant: string
}

const SocialButton: React.FC<Props> = ({ postTitle, variant }) => {
  const { classes, cx } = useStyles()
  const { href: url } = useLocation()
  const formattedUrl = encodeURIComponent(url ?? '')
  const encodedPostTitle = encodeURIComponent(postTitle)
  const loaded = useForceClientSideRender()
  const [showSuccess, setShowSuccess] = useState(false)

  const copyText = useCallback(() => {
    if (!url) {
      return
    }

    const inp = document.createElement('input')
    document.body.appendChild(inp)
    inp.value = url
    inp.select()
    document.execCommand('copy', false)
    inp.remove()

    setShowSuccess(true)

    setTimeout(() => setShowSuccess(false), 3000)
  }, [url])

  let clickAction: (() => void) | null = null
  let href = ''
  let icon = null

  switch (variant) {
    case 'copy':
      clickAction = copyText
      icon = <SvgLink className={cx(classes.icon)} />
      break
    case 'twitter':
      href = `https://twitter.com/intent/tweet?url=${formattedUrl}&text=${encodedPostTitle}`
      icon = <Twitter className={cx(classes.icon)} />
      break
    case 'facebook':
      href = `https://www.facebook.com/sharer/sharer.php?u=${formattedUrl}`
      icon = <SvgFacebook className={cx(classes.icon, classes.iconTwitter)} />
      break
    case 'linkedin':
      href = `https://www.linkedin.com/shareArticle?mini=true&url=${formattedUrl}`
      icon = <SvgLinkedIn className={cx(classes.icon)} />
      break
  }

  // @TODO Update the hook so this isn't needed as we don't like layout shifts
  if (!loaded) {
    return null
  }

  return (
    <Container className={classes.container} disableGutters>
      <IconButton
        aria-label={variant}
        className={classes.button}
        component={clickAction ? 'button' : 'a'}
        {...(clickAction
          ? { onClick: clickAction }
          : { href, target: '_blank', rel: 'noopener noreferrer' })}
        size="large"
      >
        {icon}
      </IconButton>
      {variant === 'copy' && (
        <Typography
          className={cx({
            [classes.successText]: true,
            [classes.successTextShow]: showSuccess,
          })}
          variant="caption"
        >
          Link copied
        </Typography>
      )}
    </Container>
  )
}

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const useStyles = makeStyles()((theme) => ({
  button: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.background.default,
    fontSize: '1em',
    height: '2.16em',
    marginBottom: '.78em',
    padding: '0',
    width: '2.16em',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
      marginRight: '.78em',
    },
  },
  container: {
    position: 'relative',
  },
  icon: {
    fontSize: '1em',
    height: '.94em',
    width: '.94em',
  },
  iconTwitter: {
    height: '1.2em',
    width: '1.13em',
  },
  input: {
    display: 'none',
  },
  successText: {
    animation: `${fadeIn} .25s ease-out forwards`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '.14em',
    color: theme.palette.background.default,
    display: 'none',
    left: '3em',
    minWidth: '5.5em',
    padding: '.13em .33em',
    position: 'absolute',
    top: '.32em',
    [theme.breakpoints.down('md')]: {
      left: '0',
      top: '-2.25em',
    },
  },
  successTextShow: {
    display: 'initial',
  },
}))

export default SocialButton
