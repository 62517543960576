import React, { useContext, useState } from 'react'
import { LinearProgress, LinearProgressProps } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import useDebounce from 'react-use/lib/useDebounce'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import useMeasure, { UseMeasureRef } from 'react-use/lib/useMeasure'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ScrollProgress: React.FC<
  Omit<LinearProgressProps, 'value' | 'variant'>
> = (props) => {
  const { scrollProgress } = useScrollProgress()
  const { t } = useTranslation()
  return (
    <ProgessBar
      aria-label={t('progressBar.ariaLabel')}
      role="progressbar"
      value={scrollProgress}
      variant="determinate"
      {...props}
    />
  )
}

const ProgessBar = withStyles(LinearProgress, () => ({
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  colorSecondary: {
    backgroundColor: 'transparent',
  },
}))

interface ScrollProgressProviderType {
  ref: UseMeasureRef<HTMLElement> | null
  scrollProgress: number
}

export const ScrollProgressContext =
  React.createContext<ScrollProgressProviderType>({
    ref: null,
    scrollProgress: 0,
  })

export const ScrollProgressProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [scrollProgress, setScrollProgress] = useState(0)
  const [ref, { height: elementHeight }] = useMeasure()
  const { y: windowY } = useWindowScroll()

  useDebounce(
    () => setScrollProgress(Math.min((windowY / elementHeight) * 100, 100)),
    5,
    [windowY, elementHeight]
  )

  return (
    <ScrollProgressContext.Provider
      value={{
        ref,
        scrollProgress,
      }}
    >
      {children}
    </ScrollProgressContext.Provider>
  )
}

export function useScrollProgress(): ScrollProgressProviderType {
  return useContext(ScrollProgressContext)
}

export default ScrollProgress
