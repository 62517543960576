import { useLayoutEffect, useState } from 'react'

/**
 * useForceClientSideRender is a hook that will force Gatsby to re-render the
 * component once it's loaded on the frontend. This is helpful if your component
 * has a dependency on something the browser provides.
 *
 * @TODO Make this work with refs or something so the component doesn't need to
 * early return.
 */
export default function useForceClientSideRender(): boolean {
  const [clientLoaded, setClientLoaded] = useState(false)

  useLayoutEffect(() => {
    setClientLoaded(true)
  }, [])

  return clientLoaded
}
