import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Container, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { INLINES } from '@contentful/rich-text-types'
import { Author } from '../../types/post'
import RichText from '../RichText'
import SentryErrorBoundary from '../Errors/SentryErrorBoundary'
import Image from '../ContentfulEntries/Image'

interface Props {
  author: Author
}

const AuthorProfile: React.FC<Props> = ({ author }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <SentryErrorBoundary>
      <Box className={classes.box}>
        {author.picture && (
          <Image asset={author.picture} className={classes.img} />
        )}
        <Container className={classes.container} disableGutters>
          <Typography className={classes.writtenBy} component="h3">
            {t('authorProfile.writtenBy')}
          </Typography>
          <Typography className={classes.author} variant="h4">
            {[author.name, author.title].filter((p) => !!p).join(', ')}
          </Typography>
          {author.biography && (
            <Container disableGutters>
              <RichText
                rawBody={author.biography.raw}
                options={{
                  props: {
                    [INLINES.HYPERLINK]: {
                      className: classes.postLink,
                    },
                    [INLINES.ENTRY_HYPERLINK]: {
                      className: classes.postLink,
                    },
                    [INLINES.ASSET_HYPERLINK]: {
                      className: classes.postLink,
                    },
                  },
                }}
              />
            </Container>
          )}
        </Container>
      </Box>
    </SentryErrorBoundary>
  )
}

const useStyles = makeStyles()((theme) => ({
  author: {
    margin: '.05em 0 .35em',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.25em',
  },
  container: {
    marginLeft: '1.51em',

    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  img: {
    borderRadius: '100%',
    height: '3.843em',
    width: '4.56em',

    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  writtenBy: {
    color: theme.palette.text.secondary,
    fontSize: '.81em',
    letterSpacing: '.05em',
    lineHeight: '2.15',
    textTransform: 'uppercase',
  },
  postLink: {
    textDecoration: 'underline',
  },
}))

export default AuthorProfile
