import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AssetFluid } from '../../types/asset'
import { RichText as RichTextType } from '../../types/post'
import Image from '../ContentfulEntries/Image'
import RichText from '../RichText'
import SentryErrorBoundary from '../Errors/SentryErrorBoundary'
import FiveStarReviews from '../TrustPilot/FiveStarReviews'
import SampleGallery from './SampleGallery'

interface Props {
  bannerImage: AssetFluid | null
  bannerImageMobile: AssetFluid | null
  downloadFile: string
  featuredImages: AssetFluid[] | null
  headerText: RichTextType
  occupation: string
}

const SamplePostHeader: React.FC<Props> = ({
  bannerImage,
  bannerImageMobile,
  downloadFile,
  featuredImages,
  headerText,
  occupation,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <SentryErrorBoundary>
      <Box className={classes.header} component="header">
        <Container className={classes.headerText} disableGutters>
          <Container className={classes.headerTextInner} disableGutters>
            <Typography className={classes.sampleNavigation}>
              {t('sampleHeader.navigation', {
                occupation: occupation,
              })}
            </Typography>
            <Typography className={classes.h1} variant="h1">
              {t('sampleHeader.title', { occupation: occupation })}
            </Typography>
            <RichText rawBody={headerText.raw} />
            <Container className={classes.trustpilot} disableGutters>
              <FiveStarReviews />
            </Container>
          </Container>
        </Container>
        {featuredImages && (
          <SampleGallery assets={featuredImages} file={downloadFile} />
        )}
        {bannerImage && (
          <Container className={classes.bannerImage} disableGutters>
            <Image asset={bannerImage} />
          </Container>
        )}
        {bannerImageMobile && (
          <Container className={classes.bannerImageMobile} disableGutters>
            <Image asset={bannerImageMobile} />
          </Container>
        )}
      </Box>
    </SentryErrorBoundary>
  )
}

const useStyles = makeStyles()((theme) => ({
  bannerImage: {
    '& .gatsby-image-wrapper': {
      margin: '0 auto!important',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  bannerImageMobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
    },
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    width: '100%',
  },
  h1: {
    margin: '.43em 0 .29em',
  },
  headerText: {
    maxWidth: '100%',
    padding: '3.75em 6% 14.06em 6%',

    [theme.breakpoints.down('md')]: {
      padding: '3.5em 6% 11.06em 6%',
    },
  },
  headerTextInner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '45.31em',
    textAlign: 'center',
  },
  sampleNavigation: {
    color: theme.palette.text.secondary,
    fontSize: '.88em',
  },
  starsWidget: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  trustpilot: {
    marginTop: '1.06em',
    width: 'initial',
  },
  trustpilotLegend: {
    color: theme.palette.text.secondary,
    fontSize: '.88em',
  },
}))

export default SamplePostHeader
