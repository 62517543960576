import React from 'react'
import { Button, Paper, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { AssetFluid } from '@talentinc/gatsby-theme-ecom/types/asset'
import Carousel from '../LandingPage/Carousel'
import Image from '../ContentfulEntries/Image'
import SentryErrorBoundary from '../Errors/SentryErrorBoundary'

interface Props {
  assets: AssetFluid[]
  file: string
}

const SampleGallery: React.FC<Props> = ({ assets, file }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const responsiveSettings = [
    {
      breakpoint: 960,
      settings: {
        centerMode: true,
        infinite: false,
      },
    },
  ]

  return (
    <SentryErrorBoundary>
      <Box className={classes.galleryContainer}>
        <Box className={classes.innerContainer}>
          <Button
            className={classes.button}
            href="#text-resume"
            variant="contained"
          >
            {t('sampleGallery.button')}
          </Button>
          <Box className={classes.carouselContainer}>
            {assets.length === 1 && (
              <Paper className={classes.paper}>
                <Image
                  asset={assets[0]}
                  GatsbyImageProps={{ loading: 'eager' }}
                />
              </Paper>
            )}
            {assets.length > 1 && (
              <Carousel
                dotsClass={classes.breadcrumbs}
                slidesToShow={1}
                arrowContainerLeftClass={classes.arrowContainerLeft}
                arrowContainerRightClass={classes.arrowContainerRight}
                responsiveSettings={responsiveSettings}
              >
                {assets.map((asset) => (
                  <Paper className={classes.paper} key={asset.contentful_id}>
                    <Image
                      asset={asset}
                      GatsbyImageProps={{ loading: 'eager' }}
                    />
                  </Paper>
                ))}
              </Carousel>
            )}
          </Box>
          <Typography className={classes.downloadText}>
            <Trans
              i18nKey="sampleGallery.downloadText"
              values={{
                file: file,
              }}
              components={{
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
                a: <a />,
              }}
            />
          </Typography>
        </Box>
      </Box>
    </SentryErrorBoundary>
  )
}

const useStyles = makeStyles()((theme) => ({
  arrowContainerLeft: {
    '&&': {
      left: '-7rem',
    },
  },
  arrowContainerRight: {
    '&&': {
      right: '-7rem',
    },
  },
  breadcrumbs: {
    '&&': {
      bottom: '-1.5em',
    },
  },
  button: {
    alignSelf: 'flex-end',
    fontSize: '.88em',
    fontWeight: 400,
    marginTop: '-14em',
    padding: '.57em .93em',
    [theme.breakpoints.down('md')]: {
      marginRight: '6%',
    },
  },
  carouselContainer: {
    margin: '.38em 0 1.5em',
    width: '100%',
  },
  downloadText: {
    marginTop: '1em',

    '& a': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 6% 0 6%',
      textAlign: 'center',
    },
  },
  galleryContainer: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: '4.06em',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '3.5em 0 2.69em',
    },
  },
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '45.31em',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    width: '45.31em',
    [theme.breakpoints.down('md')]: {
      width: '95% !important',
    },
  },
}))

export default SampleGallery
